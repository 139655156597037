.button {
  align-items: center;
  border-radius: 100px;
  border-style: none;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  padding: 16px;
  outline: none;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

.button:disabled,
.disabled {
  cursor: default;
  opacity: 0.48;
  pointer-events: none;
}

.buttonAutoWidth {
  padding: 16px 32px;
}

.button:enabled:hover {
  cursor: pointer;
}

.icon {
  /* Fixes https://github.com/vercel/next.js/issues/21914 */
  font-size: 0px;
  line-height: 0px;
}

.iconLeft {
  margin-right: 10px;
}

.iconRight {
  margin-left: 10px;
}

.hideChildren {
  height: 0;
  overflow-y: hidden;
}

.linkContent:hover {
  cursor: pointer;
}

.linkInternal {
  /* Otherwise, height gets messed up */
  display: flex;
}

/*
 * Themes
 */

.outlineTheme {
  /* Account for border */
  padding-bottom: 14px;
  padding-top: 14px;
}

/* Primary theme */

.primaryTheme,
.primaryThemeLink {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.primaryTheme:enabled:active,
.primaryThemeLink:active {
  background-color: var(--color-secondary);
}

@media (hover: hover) {
  .primaryTheme:enabled:hover,
  .primaryThemeLink:hover {
    background-color: var(--color-secondary);
  }
}

@media only screen and (max-width: 640px) {
  .button,
  .buttonAutoWidth {
    padding: 12px 20px;
  }

  .outlineTheme {
    /* Account for border */
    padding-bottom: 10px;
    padding-top: 10px;
  }
}