.container {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: 100%;
}

.labels {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}