.footer {
  align-items: center;
  border-top: 1px solid var(--color-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 24px;
  padding-top: 24px;
  row-gap: 8px;
}
