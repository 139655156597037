.tabs {
  background-color: var(--color-floatingElement);
  bottom: 0px;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: space-around;
  position: fixed;
  width: 100%;
  z-index: var(--z-index-bottomTabs);
}
