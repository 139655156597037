.buttonInner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.container {
  position: relative;
}

.permaPlaceholder {
  /* Keep consistent with .textInput horizontal padding in InputCommon.module.css */
  left: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
