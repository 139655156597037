.containerInner {
  align-items: center;
  border-bottom: 1px solid var(--color-primary);
  border-top: 1px solid var(--color-primary);
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  width: 100%;
}

.header {
  margin-top: 32px;
}

.navItem {
  padding-bottom: 13px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 18px;
  text-align: center;
  text-transform: uppercase;
}

.navItemWithBorders {
  border-left: 1px solid var(--color-primary);
  border-right: 1px solid var(--color-primary);
}

@media only screen and (max-width: 640px) {
  .navItem {
    padding-left: 12px;
    padding-right: 12px;
  }
}

@media only screen and (max-width: 350px) {
  .containerInner {
    display: flex;
    flex-direction: column;
  }

  .navItemWithBorders {
    border-left: none;
    border-right: none;
  }
}