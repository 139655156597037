 .bottom {
  padding-bottom: 100px;
 }

 .headerAndChildren {
  display: flex;
  flex-direction: column;
  row-gap: 36px;
 }

.top {
  padding-top: 60px;
}

@media only screen and (max-width: 1024px) {
  .bottom {
    padding-bottom: 80px;
  }
}

/* Header breakpoint */
@media only screen and (max-width: 980px) {
  .bottom {
    padding-bottom: 60px;
  }

  .top {
    padding-top: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .bottom {
    padding-bottom: 50px;
  }
}