.grid {
  column-gap: 12px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  row-gap: 12px;
}

.gridItem {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 32px;
  position: relative;
  row-gap: 40px;
  text-decoration: none;
}

.image {
  height: 100%;
  padding: 50px;
  width: 75%;
}

@media only screen and (max-width: 1024px) {
  .image {
    padding: 24px;
  }
}

@media only screen and (max-width: 768px) {
  .grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media only screen and (max-width: 540px) {
  .grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}